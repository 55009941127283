.sales-box {
  min-width: 350px;
}

.sales-stock {
  min-width: 500px;
}

.btn-left {
  border-radius: 5px 0px 0px 5px;
}

.btn-right {
  border-radius: 0px 5px 5px 0px;
}

.barcode-input {
  border-radius: 5px 0px 0px 5px;
}

.btn-label-action {
  margin: 0;
}

#cart-container {
  min-height: 55%;
}

.items-table {
  display: block;
  overflow-y: auto;
  max-height: 50vh;
  min-width: 100%;
}

.payment-items-box {
  max-height: 180px;
  overflow-y: auto;
}

.grid-rows-8 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
